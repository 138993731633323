<template>
    <div :class="[flag? '': 'content-box']">
        <div :class="[flag? '': 'container']">
            <div class="content">
            <h4 class="global_head_title">工人详情</h4><br>
            <div class="content_row flex">
                <label class="info" for="">姓名</label>
                  <div class="content_right">
                    <!-- <a-input v-model="data.name" size="large" placeholder="请输入姓名"/> -->
                    {{data.name}}
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">性别</label>
                  <div class="content_right">
                       {{data.sex==1?'男':'女'}}
                     <!-- <a-select v-model="data.sex" class="ant-select-lg">
                        <a-select-option :value="1">男</a-select-option>
                        <a-select-option :value="2">女</a-select-option>
                    </a-select> -->
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">年龄</label>
                  <div class="content_right">
                    {{data.age}}
                    <!-- <a-input v-model="data.age" size="large" type="number" placeholder="请输入身份证号"/> -->
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">身份证号</label>
                  <div class="content_right">
                    {{data.code}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">电话</label>
                  <div class="content_right">
                    {{data.tel}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">是否有工伤保险</label>
                  <div class="content_right">
                      {{data.insurance==1?'有':'没有'}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">保险到期时间</label>
                  <div class="content_right">
                   {{deadline=='1970-01-01'?'':deadline}}
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">技能</label>
                  <div class="content_right">
                     {{selSkillid}}

                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">状态</label>
                  <div class="content_right">
                   {{data.status=='2'?'工作中':'空闲中'}}
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">职务</label>
                  <div class="content_right">
                   {{data.post}}
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">银行卡号</label>
                  <div class="content_right">
                  {{data.card}}
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">跟进人</label>
                  <div class="content_right">
                   {{data.responsibility_name}}
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">紧急联系人</label>
                  <div class="content_right">
                   {{data.contacts}}
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">紧急联系人电话</label>
                  <div class="content_right">
                    {{data.contactstel}}
                </div>
            </div>
            <div class="content_row flex" >
                <label class="info" for="">备注</label>
                <div class="content_right">
                    {{data.remarks}}
                </div>
            </div>
             <div class="content_row flex" >
                <label class="info" for="">回访记录</label>
                <div class="content_right">
                     <div style="width:80%;text-align:center">
                     <a-button type="primary" class="editable-add-btn" @click="showEditRecord()" style="margin-bottom:10px">新增回访</a-button>
                    <a-table :columns="interviewColumns" :data-source="interview" class="table" :bordered="true" :pagination="false" >
                        <template slot="Action" slot-scope="text,record">
                            <a class="action" href="javascript:;" @click="showEditRecord(record)">编辑</a>
                            <a class="action del" href="javascript:;" @click="delRecord(record.id)">删除</a>
                        </template>
                    </a-table>
                     </div>

                </div>
            </div>
            <!-- <div style="font-size:24px;margin:50px 0 30px">
                回访记录
            </div>
            <table width="100%" style="line-height:45px">
                <tr>
                    <th width="20%">时间</th>
                    <th>回访人</th>
                    <th width="50%">回访内容</th>
                    <th>操作</th>
                </tr>
                <tr v-for="(item,index) in interview" :key="index">
                    <td>{{item.time}}</td>
                    <td>{{item.uid}}</td>
                    <td>{{item.record}}</td>
                    <td class="action1"> <span @click="showEditRecord(item)"> 编辑</span><span @click="delRecord(item.id)">删除</span></td>
                </tr>
            </table>
            <div class="content_row flex" style="margin-top:30px;display: flex;flex-direction: column;align-items: flex-start;">
                <div for="" style="padding-bottom:10px">添加记录</div>
                <div class="content_right">
                    <a-textarea v-model="record" placeholder="请输入回访记录" :auto-size="{ minRows: 5, maxRows: 8 }"/>
                </div>
            </div> -->
            <a-modal v-model="visible" centered="true" title="回访记录"  @ok="submit">
            <div class="addBox">
                <div class="add_title">请输入内容</div>
                 <a-textarea  class="add_inp" v-model="editText" :auto-size="{ minRows: 5, maxRows: 8 }" />
            </div>
        </a-modal>
            <!-- <div class="save"><a-button type="primary" @click="submit" style="margin-left: 0;">保存</a-button></div> -->
            </div>
        </div>
        </div>
</template>
<script>
// import $ from "jquery";
import {isMobile,ajaxUrl,requestXml, getDateTime} from '../../../assets/js/request';
export default {
    data() {
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            staff:'', //员工列表
            skillid:'', //技能列表
            data:{
                "name": "",
                "sex": "",
                "age": "",
                "code": "",
                "tel": "",
                "insurance": "",
                "skillid": "",
                'deadline':'',
                "status": "",
                "post": "",
                "card": "",
                "contacts": "",
                "contactstel": "",
                "uid": "",
                "remarks": "",
            },
               interviewColumns:[
                { title: '时间', dataIndex: 'time'},
                { title: '回访人', dataIndex: 'uid'},
                { title: '回访内容', width:'50%',dataIndex: 'record' },
                {
                    title: '操作',
                    width: 100,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            deadline:'', //保险到期时间
            selSkillid:'',  //技能选项
            interview:'',  //回访记录
            record:'',  //新增回访记录
            onRecord:'', //修改中的回访记录
            editText:'',
            visible:false,
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        // this.getStaff();
        // this.getSkillid();
        this.getRecord();
         if(this.id){
            this.getDetail();
        }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
        submit(){
            let url='/jieb/Temporary/interviewadd';
               var time=new Date().getTime();
            let data={
                'temporaryid':this.id,
                'record':this.editText,
                'time':Math.round(time/1000)
            }
            if(this.onRecord){
                data.id=this.onRecord.id;
                url='/jieb/Temporary/interviewedit';
            }

             requestXml(url,"POST",(status,res) => {
                console.log(status,res);
                if(status!='error'){
                    if(!this.onRecord){this.$message.success('成功')}                   
                    this.visible=false;
                    this.getRecord();
                    this.onRecord='';
                    this.editText='';
                }
                // if(res){
                //     this.$message.info(res.msg);
                // }
            },data)
        },
        showEditRecord(item){
            this.visible=true;
            if(item){
                this.onRecord=item;
                this.editText=item.record;
            }else{
                this.onRecord='';
                this.editText='';
            }
            
        },
        delRecord(id){
            requestXml("/jieb/Temporary/interviewdel","POST",() => {
                   this.getRecord();
                   this.onRecord='';
                   this.visible=false;
                   this.editText='';
            },{id:id})
        },
//         editRecord(){
//                var time=new Date().getTime();
//                requestXml("/jieb/Temporary/interviewedit","POST",() => {
//                    this.getRecord();
//                    this.onRecord='';
//                    this.visible=false;
//                    this.editText='';
//             },{id:this.onRecord.id,temporaryid:this.id,record:this.editText,'time':Math.round(time/1000)
// });
//         },
        getRecord(){
              requestXml("/jieb/Temporary/tempbasedetails","POST",(res) => {
               this.selSkillid=res.list.skillid;
               this.interview=res.interview;
            },{id:this.id});
        },
        onPickTime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.data.deadline= Math.round(time/1000); 
        },
        // 获取技能分类
        // getSkillid(){
        //      requestXml("/jieb/Temporary/skillshow","POST",(res) => {
        //         this.skillid=res.list;
        //         // console.log(this.skillid);
        //         // console.log(JSON.stringify( this.skillid));
        //         if(this.id){
        //             this.getDetail();
        //         }
        //         // console.log(this.skillid);
        //     },{"page":{"curpage":1,"pagesize":999999}})
        // },
         getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staff=res;
            })
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Temporary/tempbasedit","GET",(res) => {
            //    console.log(res);
            //     if(res.skillid){
            //         let selSkillid=res.skillid.split(',');
            //         for (let index = 0; index < selSkillid.length; index++) {
            //             selSkillid[index]=Number(selSkillid[index]);
            //         }
            //         this.selSkillid=selSkillid;
            //     }
                this.deadline=getDateTime(res.deadline);
                console.log(this.deadline);
                this.data = Object.assign({}, res);
            },{id: this.id})
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
    .add_title{padding: 15px 0;}
    .add_inp{margin-bottom: 60px;}
    .action1 span{text-decoration:underline; cursor: pointer;margin: 0 10px;}
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 125px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        
    }
</style>
